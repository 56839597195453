import React, { useEffect, useRef, useState } from 'react'
import { FieldWrapper, InputText, Label, StyledInput } from './styles'
import { Close, ExpandMore, ExpandLess } from '@material-ui/icons'

import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'
import { debounce } from '@utils'

const SelectStyledInput = styled(StyledInput)`
	padding: ${({ small, noDropIcon }) =>
		small
			? `0 ${noDropIcon ? Spacings.SPACING_2B : Spacings.SPACING_6B} 0 ${Spacings.SPACING_2B}`
			: `0 ${noDropIcon ? Spacings.SPACING_2B : Spacings.SPACING_9B} 0 ${Spacings.SPACING_3B}`};
`

const OptionsList = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	border: 1px solid ${({ theme }) => theme.palette.background.border};
	border-radius: ${Spacings.SPACING_2B};
	position: absolute;
	width: 100%;

	top: ${({ isTopPadding, openBelow }) => (openBelow ? (isTopPadding ? '100%' : 'calc(100% - 0.5rem)') : null)};
	left: 0;
	bottom: ${({ openBelow }) => (!openBelow ? 'calc(0% + 2.5rem)' : null)};
	z-index: 2;

	transition: all 0.15s ease-in-out;
	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	max-height: ${({ show }) => (show ? '18rem' : '0')};

	z-index: 2;
	overflow: scroll;
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

const Option = styled.div`
	width: 100%;
	max-width: 100%;
	min-height: ${({ small }) => (small ? '1.75rem' : '2.5rem')};
	display: flex;
	align-items: center;
	padding: 0 ${Spacings.SPACING_3B};
	font-size: ${({ small }) => (small ? Text.SMALL : Text.MEDIUM)};
	cursor: pointer;
	color: ${({ theme }) => theme.palette.text.black};

	transition: background 0.15s ease-in-out;

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLightest};
	}

	${({ selected }) => {
		if (selected) {
			return css`
				color: ${({ theme }) => theme.palette.text.darkblack};
				background: ${({ theme }) => theme.palette.background.blueLightest};
			`
		}
	}}

	${({ createItemOption }) => {
		if (createItemOption) {
			return css`
				padding: 0;
			`
		}
	}}

	& >span {
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
	}
`

const NoOption = styled(Option)`
	pointer-events: none;
	user-select: none;
`

const IconWrapper = styled.div`
	position: absolute;
	top: ${({ small }) => (small ? Spacings.SPACING_3B : Spacings.SPACING_4B)};
	right: ${({ small }) => (small ? Spacings.SPACING_1B : Spacings.SPACING_2B)};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	padding: 0.125rem;
	background: ${({ theme }) => theme.palette.background.white};
	color: ${({ theme }) => theme.palette.text.black};

	transition: all 0.15s ease-in-out;

	& > svg {
		font-size: ${({ small }) => (small ? Text.LARGE : Text.M_LARGE)};
	}

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLightest};
		color: ${({ theme }) => theme.palette.text.darkblack};
	}
`

const CreateItemButton = styled.button`
	width: 100%;
	max-width: 100%;
	min-height: 2.25rem;
	border: none;
	background: #1967d2;
	color: white;
	cursor: pointer;
`

const Select = React.memo(
	({
		id,
		label,
		data,
		value,
		displayKey,
		onChange,
		readOnly,
		disabled,
		primaryKey,
		uppercase,
		isTopPadding = false,
		small,
		noDropIcon,
		inline,
		openBelow = true,
		...props
	}) => {
		const elem = useRef()
		const listRef = useRef()
		const cancelRef = useRef()
		const filterFunctionRef = useRef()

		const [focus, setFocus] = useState(props?.autofocus)
		const [inputValue, setInputValue] = useState('')
		const [options, setOptions] = useState([])

		const showCloseIcon = (focus && inputValue) || props.cancelClick

		useEffect(() => {
			filterFunctionRef.current = debounce(filterOptions, 200)
		}, [])

		useEffect(() => {
			if (value !== undefined) {
				setInputValue(value[displayKey] ?? '')
			}
		}, [value, displayKey])

		useEffect(() => {
			const pointerDownHandler = (e) => {
				const target = e.target
				if (target && elem.current && listRef.current)
					if (
						target !== elem.current &&
						target !== listRef.current &&
						!listRef.current.contains(target) &&
						(!cancelRef.current || (cancelRef.current && !cancelRef.current.contains(target)))
					) {
						setFocus(false)
					}
			}

			if (focus) {
				setInputValue('')
				setOptions(data)
				window.addEventListener('pointerdown', pointerDownHandler)
			} else if (value) {
				setInputValue(value[displayKey] ?? '')
			}

			return () => {
				window.removeEventListener('pointerdown', pointerDownHandler)
			}
		}, [focus])

		useEffect(() => {
			setOptions(data)
		}, [data])

		useEffect(() => {
			if (focus && filterFunctionRef.current) {
				filterFunctionRef.current(data, inputValue)
			}
		}, [inputValue])

		const filterOptions = (data, searchString) => {
			if (searchString) {
				const filteredOptions = []
				data?.forEach((option) => {
					if (option[displayKey].toLowerCase().replaceAll(' ', '').includes(searchString.toLowerCase().replaceAll(' ', ''))) {
						filteredOptions.push(option)
					}
				})
				setOptions(filteredOptions)
			} else {
				setOptions(data)
			}
		}

		const changeHandler = (e) => {
			let value = e.target.value ?? ''
			if (uppercase) {
				value = value.toUpperCase()
			}
			setInputValue(value)
		}

		const focusHandler = (e) => {
			setFocus(true)
		}

		const optionClickHandler = (e) => {
			e.stopPropagation()
			const target = e.target
			let optionIndex = target.dataset.index
			// debugger
			if (optionIndex !== undefined) {
				optionIndex = isNaN(+optionIndex) ? optionIndex : +optionIndex
				//dont check for var type (===). issue for numerical primaryKey
				const selectedOption = options.find((option) => option[primaryKey] == optionIndex)
				if (value !== undefined && value[primaryKey] != optionIndex) {
					onChange(selectedOption)
				}
				setFocus(false)
			}
		}

		const cancelClickHandler = (e) => {
			e.stopPropagation()
			setInputValue('')
			elem.current.focus()
			props.cancelClick && props.cancelClick()
			if (!showCloseIcon && focus) {
				setFocus(false)
			}
		}

		const renderOptions = () => {
			return (
				<OptionsList
					isTopPadding={isTopPadding}
					show={focus}
					onClick={optionClickHandler}
					ref={(_ref) => (listRef.current = _ref)}
					small={small}
					openBelow={openBelow}
				>
					{options?.length
						? options.map((item) => {
								const _i = item[primaryKey]
								if (_i === undefined || value === undefined) {
									return null
								}
								return (
									<Option data-index={_i} key={`select-${id}-option-${_i}`} selected={_i === value[primaryKey]} small={small}>
										<span>{item[displayKey]}</span>
									</Option>
								)
						  })
						: renderCreateNewItem()}
				</OptionsList>
			)
		}
		const onCreateItemClickHandler = () => {
			setFocus(false)
			const optionValue = {
				// primaryKey: 'product_name',
				// displayKey: 'product_id',
				// product_name: inputValue,
				// product_id: inputValue,
				// is_new_item: true,
				primaryKey: primaryKey ?? null,
				displayKey: displayKey ?? null,
				[primaryKey]: inputValue,
				[displayKey]: inputValue,
				is_new_item: true,
			}
			onChange(optionValue)
			// setInputValue({displayKey: inputValue})
		}

		const renderCreateNewItem = () => {
			return (
				<>
					<NoOption small={small}>
						No match Found.
						{!!inputValue && props?.createNewOption}
					</NoOption>
					{!!inputValue && props?.createNewOption && (
						<Option createItemOption={true} small={small}>
							<CreateItemButton onClick={onCreateItemClickHandler}>Click here to select entered product</CreateItemButton>
						</Option>
					)}
				</>
			)
		}

		const renderIcon = () => {
			if (showCloseIcon) {
				return <Close />
			}
			return focus ? <ExpandLess /> : <ExpandMore />
		}

		return (
			<FieldWrapper isTopPadding={isTopPadding} inline={inline}>
				<InputText show={readOnly} showingPlaceholder={value === undefined || value === null || value === ''} small={small}>
					{value ? value[displayKey] : props?.placeholder}
				</InputText>
				<Label readOnly={readOnly} htmlFor={id} focus={focus} small={small}>
					{label}
				</Label>
				<SelectStyledInput
					small={small}
					ref={(_ref) => (elem.current = _ref)}
					show={!readOnly}
					disabled={readOnly || disabled}
					id={id}
					value={inputValue}
					onChange={changeHandler}
					onFocus={focusHandler}
					uppercase={uppercase}
					noDropIcon={noDropIcon}
					autoComplete='off'
					{...props}
				/>
				{!noDropIcon && !readOnly && !disabled && (
					<IconWrapper onClick={cancelClickHandler} ref={(_ref) => (cancelRef.current = _ref)} small={small}>
						{renderIcon()}
					</IconWrapper>
				)}
				{renderOptions()}
			</FieldWrapper>
		)
	}
)

export default Select
