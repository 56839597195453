import API from '../api'
import postUrl from './paths'

export const createEnquiry = (enquiry, user, newAddressAdded = false) =>
	new Promise((resolve, reject) => {
		enquiry.created_by = user.user_id
		enquiry.created_at = new Date().getTime()
		API.post(postUrl.createEnquiry, enquiry, { newAddressAdded })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const downloadEstimate = (enquiryId, data) =>
	new Promise((resolve, reject) => {
		API.post(
			postUrl.downloadEstimate,
			data,
			{ enquiryId },
			{
				responseType: 'arraybuffer',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/pdf',
				},
			}
		)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const convertToOrder = (enquiryId, userId, orderDate, costMetrics) =>
	new Promise((resolve, reject) => {
		if (!orderDate) {
			orderDate = new Date().getTime()
		}
		API.post(postUrl.convertToOrder, costMetrics, { enquiryId, userId, orderDate })
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
