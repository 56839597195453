import React, { useEffect, useState } from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styled from 'styled-components'
import { H6 } from '@base'
import { strings } from '@constants'
import { getOrganisation } from '@data'
import { AccountBalance, AttachMoney, HowToReg, ImportExport, Repeat, ThumbsUpDown, TouchApp } from '@material-ui/icons'
import { Devices, Spacings, Text } from '@styles'
import { displayAmount } from '@utils'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${Spacings.SPACING_2B};

	@media ${Devices.tablet} {
		padding: 0;
	}
	@media ${Devices.laptop} {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

const MainWrapper = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(1, 1fr);

	@media ${Devices.tablet} {
		grid-template-columns: repeat(2, 2fr);
		grid-column-gap: ${Spacings.SPACING_4B};
	}
	@media ${Devices.laptop} {
		grid-template-columns: repeat(4, 4fr);
	}
`

const RecordWrapper = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	margin-bottom: ${Spacings.SPACING_4B};
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	height: 6rem;
	overflow: hidden;

	@media ${Devices.mobile} {
		border-radius: 0;
	}
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
	}
`
const LeftSideWapper = styled.div`
	margin: ${Spacings.SPACING_3B};
`

const TitleWrapper = styled(H6)`
	color: ${({ theme }) => theme.palette.text.black};
	display: flex;
	flex-direction: row;
	align-items: center;
	&:nth-of-type(2) {
		margin-left: ${Spacings.SPACING_3B};
	}
`
const TotalWrapper = styled.div`
	font-size: ${Text.LARGE};
	margin: ${Spacings.SPACING_6} 0px;
	color: ${({ theme }) => theme.palette.text.darkblack};
	font-weight: bold;
`

const RightSideWapper = styled.div`
	position: relative;
	width: ${Spacings.SPACING_16B};
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		background: ${({ theme }) => theme.palette.background.greenLightest};
		content: '';
		position: absolute;
		width: 16rem;
		height: 7.3rem;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 60%;
	}
	& > svg {
		fill: ${({ theme }) => theme.palette.background.greenDark};
	}
`

export const IconWrapper = styled.div`
	color: ${({ theme }) => theme.palette.text.blue};
	margin-left: ${Spacings.SPACING_1B};
	cursor: initial;
	z-index: 497;

	& > div {
		visibility: hidden;
		opacity: 0;
	}
	&:hover {
		cursor: pointer;
		z-index: 499 !important;
		& > div {
			visibility: visible;
			opacity: 1;
		}
	}
`

export const ViewInfoCard = styled.div`
	position: absolute; 
	display: inline-block;
	font-size: ${Text.SMALL};
	color: ${({ theme }) => theme.palette.text.black};
	transform: translateX(-30%);

	& > span {
		position: absolute;
		background: ${({ theme }) => theme.palette.background.white};
		box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_2B} 0 rgb(0 0 0 / 25%);
		border-radius: ${Spacings.SPACING_2B};
		padding: ${Spacings.SPACING_2B};
		width: max-content;
		max-width: 180px;
		max-height: 120px;
		bottom: 110%;
		left: 50%;
		z-index: 498;
	}
`

export const CardDetail = ({ heading, value, icon, definition, hasDefinition = true }) => {
	return (
		<RecordWrapper>
			<LeftSideWapper>
				<TitleWrapper>
					{heading}
					{hasDefinition && (
						<IconWrapper>
							<InfoOutlinedIcon fontSize='small' />
							<ViewInfoCard>
								<span>{definition}</span>
							</ViewInfoCard>
						</IconWrapper>
					)}
				</TitleWrapper>
				<TotalWrapper>{value || '-'}</TotalWrapper>
			</LeftSideWapper>
			<RightSideWapper>{icon}</RightSideWapper>
		</RecordWrapper>
	)
}

const CompanyReport = ({ forceUpdate }) => {
	const [data, setData] = useState({})

	useEffect(() => {
		const organisationId = 'OG55AtI-9722' // organisationId of Mbook
		getOrganisation(organisationId)
			.then((response) => {
				setData(response?.company_report)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [forceUpdate])
	return (
		<Wrapper>
			<MainWrapper>
				<CardDetail
					definition={strings('total_revenue_def')}
					heading={strings('total', 'revenue')}
					value={data?.total_revenue ? displayAmount(data?.total_revenue / 10000000) + ' Cr' : '-'}
					icon={<AccountBalance fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('clv_def')}
					heading={strings(['customer', 'lifetime', 'value'])}
					value={data?.clv ? displayAmount(data?.clv / 100000) + ' Lac' : '-'}
					icon={<HowToReg fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('ccr_def')}
					heading={strings(['conversion', 'rate'])}
					value={data?.ccr ? `${data?.ccr} %` : '-'}
					icon={<ThumbsUpDown fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('activation_rate_def')}
					heading={strings(['activation', 'rate'])}
					value={data?.ar ? `${data?.ar} %` : '-'}
					icon={<TouchApp fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('ror_def')}
					heading={strings(['repeat', 'order', 'ratio'])}
					value={data?.ror ? `${data?.ror} %` : '-'}
					icon={<Repeat fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('arpa_def')}
					heading={strings(['average', 'revenue', 'per', 'account'])}
					value={data?.arpa ? displayAmount(data?.arpa / 100000) + ' Lac' : '-'}
					icon={<AttachMoney fontSize='medium' />}
				/>
				<CardDetail
					definition={strings('buyer_to_seller_ratio_def')}
					heading={strings(['buyer', 'to', 'seller', 'ratio'])}
					value={data?.btsr ? data?.btsr : '-'}
					icon={<ImportExport fontSize='medium' />}
				/>
			</MainWrapper>
		</Wrapper>
	)
}

export default CompanyReport
