import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, Textarea, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { FaqItemWrapper, Section } from '../styles'
import { editContent } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'

const deriveFormState = () => {
	let _state = {
		question: {
			id: 'faq-question',
			label: strings('question'),
			placeholder: strings('enter', 'question'),
			value: '',
			required: true,
		},
		answer: {
			id: 'faq-answer',
			label: strings('answer'),
			placeholder: strings('enter', 'answer'),
		},
	}
	return _state
}

const AddFaq = ({ data, update }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveFormState())
	const loggedInUser = useSelector((state) => state.getIn(['user', 'user']))

	const updateState = (key) => (value) => {
		setFormState((_s) => ({ ..._s, [key]: { ..._s[key], value } }))
	}

	const disabled = useMemo(() => {
		if (!formState.answer?.value || !formState.question?.value) {
			return true
		}
		return false
	}, [formState])

	const addFaqHandler = () => {
		const _faq = data?.faq ?? []
		_faq.push({
			question: formState?.question?.value,
			created_by: loggedInUser?.user_id,
			created_at: new Date().getTime(),
			answer: formState?.answer?.value,
		})
		dispatch(showLoader(true))
		editContent({ ...data, faq: _faq }, 'BLOG')
			.then((response) => {
				update()
				dispatch(showToast(true, strings('msg_faq_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				dispatch(showToast(true, strings('msg_faq_added_failure'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
				dispatch(toggleDrawer(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FaqItemWrapper>
					<Input
						id={formState.question?.id}
						type='text'
						label={formState.question?.label}
						placeholder={formState.question?.placeholder}
						value={formState.question?.value}
						required={formState.question?.required}
						onChange={updateState('question')}
					/>
				</FaqItemWrapper>
				<FaqItemWrapper>
					<Textarea
						id='quote-summary'
						label={formState.answer?.label}
						type='text'
						rows={6}
						placeholder={formState.answer?.placeholder}
						value={formState.answer?.value}
						onChange={updateState('answer')}
						maxlength={10}
					/>
				</FaqItemWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small onClick={addFaqHandler} disabled={disabled}>
					{strings('add')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddFaq
