import API from '../api'
import getUrl from './paths'
import { enquiryStatus as enquiryStatusC } from '@constants'
import convertPagableData from '../convertPagableData'

export const getAllEnquiries = (enquiryStatus = enquiryStatusC.created, page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getEnquiries, { enquiryStatus, page, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getBuyerEnquiries = (page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getBuyerEnquiries, { page, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getSupplierEnquiries = (page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getSupplierEnquiries, { page, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getEnquiryById = (enquiryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getEnquiryById, { enquiryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getAdminEnquiries = (enquiryStatus = enquiryStatusC.created, commodityFilter, page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAdminEnquiries, { enquiryStatus, commodityFilter, page, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAdminAssignedEnquiries = (enquiryStatus = enquiryStatusC.unverified, employeeId, commodityFilter, page = 0, enquiryFilters = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAdminAssignedEnquiries, { enquiryStatus, page, employeeId, commodityFilter, ...enquiryFilters })
			.then((response) => {
				resolve(convertPagableData(response.data))
			})
			.catch((err) => reject(err))
	})

export const getAllProductPhotosBuyer = (orderId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getAllProductPhotosBuyer, { orderId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getScrapRequirement = () =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getScrapRequirement)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getScrapSupplierQuotes = (city) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getScrapSupplierQuotes, { city })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getUserAddressesApi = (userId = null) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getUserAddresses, { userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const getScrapSupplierQuotesV2 = (city) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getScrapSupplierQuotesV2, { city })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const getExpectedTransitTime = (enquiryId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.getExpectedTransitTime, { enquiryId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
