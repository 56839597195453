import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE } from '@base'
import { CountryCodes, enquiryPlatform, LEADS_INDUSTRY, LEADS_SOURCE, REGION_TYPES, strings } from '@constants'
import { FormHeading } from '../../style'
import { Devices, Spacings } from '@styles'
import { createLead, getPanByCompanyName } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { getNumberWithCountryCode } from '@utils'
import { toggleDrawer } from '@data/state/action/root'
import { updateLead } from '@data/conn/put/leads'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;

	margin-top: ${Spacings.SPACING_2B};
	& > div {
		width: 100%;
	}
`
const InputWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
	& > div {
		width: 100%;
	}
`
export const BiInputWrapper = styled.div`
	display: grid;
	grid-template-columns: ${({ isPhone }) => (isPhone ? `1fr 6fr` : `3fr 3fr`)};
	column-gap: ${Spacings.SPACING_2B};
`

const SOURCE = Object.entries(LEADS_SOURCE).map(([key, value]) => ({ key, label: value }))
const BUSINESS_CATEGORY = Object.entries(enquiryPlatform).map(([k, value]) => ({ key: value.name, label: value.name }))
const COMPANY_INDUSTRY = Object.entries(LEADS_INDUSTRY).map(([key, value]) => ({ key, label: value }))
const regionTypes = Object.entries(REGION_TYPES).map(([key, value]) => ({ key, label: value }))

const deriveState = (data) => {
	const _state = {
		user_name: {
			id: 'user_name',
			placeholder: strings('user', 'name'),
			value: '',
			label: strings('user', 'name'),
			required: true,
		},
		email: {
			id: 'email',
			placeholder: strings('user', 'email'),
			value: '',
			label: strings('user', 'email'),
			required: false,
		},
		country_code: {
			id: 'org_country_code',
			value: CountryCodes[0],
			required: true,
		},
		mobile_number: {
			id: 'mobile_number',
			placeholder: strings('phone', 'number'),
			value: '',
			label: strings('phone', 'number'),
			required: true,
			valid: null,
		},
		pan: {
			id: 'pan',
			placeholder: strings(['enter', 'pan', 'number']),
			value: '',
			label: strings('pan', 'number'),
			required: true,
			valid: null,
		},
		organisation_name: {
			id: 'organisation_name',
			placeholder: strings(['enter', 'organisation', 'name']),
			value: '',
			label: strings('organisation', 'name'),
			required: true,
		},
		closure_date: {
			id: 'closure_date',
			placeholder: strings(['enter', 'closure', 'date']),
			value: new Date().getTime(),
			label: strings('closure', 'date'),
			required: true,
		},
		source: {
			id: 'source',
			placeholder: strings(['select', 'source']),
			primaryKey: 'key',
			displayKey: 'label',
			data: SOURCE,
			value: {},
			label: strings('source'),
			required: false,
		},
		category: {
			id: 'business_category',
			placeholder: strings(['select', 'business', 'category']),
			primaryKey: 'key',
			displayKey: 'label',
			data: BUSINESS_CATEGORY,
			value: {},
			label: strings('business', 'category'),
			required: false,
		},
		industry: {
			id: 'industry',
			placeholder: strings(['select', 'company_industry']),
			primaryKey: 'key',
			displayKey: 'label',
			data: COMPANY_INDUSTRY,
			value: {},
			label: strings('company_industry'),
			required: false,
		},
		region: {
			id: 'region',
			label: strings('region'),
			placeholder: strings('region'),
			primaryKey: 'key',
			displayKey: 'label',
			data: regionTypes,
			value: {},
			required: false,
		},
		location: {
			id: 'location',
			placeholder: strings(['enter', 'location']),
			value: '',
			label: strings('location'),
			required: true,
		},
		turnover: {
			id: 'turnover',
			placeholder: strings(['enter', 'turnover']),
			value: '',
			label: strings(['turnover', '(in Cr)']),
			required: false,
		},
	}
	if (data) {
		_state.email.value = data?.user_email
		_state.user_name.value = data?.user_details?.full_name
		_state.country_code.value = CountryCodes.find((item) => item.code === data.country_code) || CountryCodes[0]
		_state.mobile_number.value = data?.user_details?.mobile_number
		_state.pan.value = data?.pan
		_state.organisation_name.value = data?.user_details?.organisation_name
		_state.location.value = data?.location
		_state.closure_date.value = data?.expected_closure_date
		_state.turnover.value = data?.turnover
		_state.source.value = SOURCE.find((item) => item.key === data?.source) || {}
		_state.category.value = BUSINESS_CATEGORY.find((item) => item.key === data?.category) || {}
		_state.industry.value = COMPANY_INDUSTRY.find((item) => item.key === data?.industry) || {}
		_state.region.value = regionTypes.find((item) => item.key === data?.region) || {}
		if (data?.region === REGION_TYPES.EXPORT) {
			_state.pan.required = false
		}
	}
	return _state
}

const AddLead = ({ update, data, isEdit = false }) => {
	const [formState, setFormState] = useState(deriveState(data))
	const dispatch = useDispatch()
	const [lead, setLead] = useState({})
	const userId = useSelector((state) => state.getIn(['user', 'user', 'user_id']))
	const [panByCompanyName, setPanByCompanyName] = useState([])
	const [_orgName, setOrgName] = useState()

	const updateState = (field, key) => (value) => {
		setFormState((state) => ({
			...state,
			[field]: {
				...state[field],
				[key]: value,
			},
		}))

		if (isEdit) {
			if (field === 'user_name') {
				setLead({
					...lead,
					['user_details']: {
						['full_name']: value,
					},
				})
			} else if (field === 'organisation_name') {
				setLead({
					...lead,
					['user_details']: {
						[field]: value,
					},
				})
			} else if (field === 'source' || field === 'category' || field === 'industry' || field === 'region') {
				if (field === 'region') updateState('pan', 'required')(value.key !== REGION_TYPES.EXPORT)
				setLead({ ...lead, [field]: value.key })
			} else if (field === 'closure_date') {
				setLead({ ...lead, ['expected_closure_date']: value })
			} else if (field === 'email') {
				setLead({ ...lead, ['user_email']: value })
			} else {
				setLead({ ...lead, [field]: value })
			}
		} else {
			if (field === 'country_code') {
				setLead({ ...lead, [field]: value?.code })
			} else if (field === 'source' || field === 'category' || field === 'industry' || field === 'region') {
				if (field === 'region') updateState('pan', 'required')(value.key !== REGION_TYPES.EXPORT)
				setLead({ ...lead, [field]: value.key })
			} else {
				setLead({ ...lead, [field]: value })
			}
		}
	}

	const userNameHander = (orgName) => {
		const userName = formState?.user_name?.value?.trim()
		if (userName === _orgName || userName === '') {
			return
		}
		dispatch(showLoader(true))
		getPanByCompanyName(orgName?.trim())
			.then((res) => {
				const _res =
					res?.length > 0
						? res?.map((item) => {
								item['display_name'] = `${item.pan + ', (' + item.company_name + ')'}`
								return item
						  })
						: []
				setPanByCompanyName(_res)
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, err?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				setOrgName(userName)
				dispatch(showLoader(false))
			})
	}

	const panValidation = () => {
		let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
		return regpan.test(formState.pan?.value?.pan) ? true : false
	}

	const addLeadHandler = () => {
		if (typeof formState.pan?.value === 'object' && !panValidation()) {
			dispatch(showToast(true, 'Invalid pan number', { type: TOAST_TYPE.ERROR }))
			return
		}
		dispatch(showLoader(true))
		createLead(userId, {
			...lead,
			closure_date: formState.closure_date.value,
			country_code: formState.country_code?.value?.code,
			mobile_number: getNumberWithCountryCode(formState.country_code?.value, formState?.mobile_number.value),
			pan: typeof formState.pan?.value === 'object' ? formState.pan?.value?.pan : formState.pan?.value,
		})
			.then(() => {
				dispatch(showToast(true, 'Successfully added lead', { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
				dispatch(toggleDrawer(false))
			})
	}

	const editLeadHandler = () => {
		if (typeof formState.pan?.value === 'object' && !panValidation()) {
			dispatch(showToast(true, 'Invalid pan number', { type: TOAST_TYPE.ERROR }))
			return
		}
		dispatch(showLoader(true))
		let userDetails = { ...data['user_details'], ...lead['user_details'] }
		let leadObj = {
			...data,
			...lead,
			user_details: userDetails,
			country_code: formState.country_code?.value?.code,
			mobile_number: getNumberWithCountryCode(formState.country_code?.value, formState?.mobile_number.value),
			pan: typeof formState.pan?.value === 'object' ? formState.pan?.value?.pan : formState.pan?.value,
		}
		updateLead(leadObj)
			.then((response) => {
				dispatch(showToast(true, 'Successfully updated lead', { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(showLoader(false))
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while updating lead', { type: TOAST_TYPE.ERROR }))
			})
	}

	const disabled = useMemo(() => {
		return Object.values(formState).some((d) => d.required && !d.value) || !formState.mobile_number.valid
	}, [formState])

	return (
		<>
			<DrawerBodyWrapper>
				<SectionItemWrapper>
					<FormHeading>{strings('basic', 'details')}</FormHeading>
					<Input
						id={formState.user_name.id}
						label={strings('user', 'name')}
						type='text'
						placeholder={strings('user', 'name')}
						value={formState.user_name.value}
						onChange={updateState('user_name', 'value')}
						required={formState.user_name.required}
						errorMap={{
							valueMissing: `${strings('name_required')}`,
						}}
						onBlur={(e) => {
							userNameHander(e?.target?.value)
						}}
					/>
					<InputWrapper>
						<BiInputWrapper isPhone>
							<Select
								id={formState.country_code.id}
								data={CountryCodes}
								displayKey='dial_code'
								primaryKey='name'
								value={formState.country_code.value}
								onChange={updateState('country_code', 'value')}
								type='tel'
								noDropIcon
								disabled={isEdit}
							/>
							<Input
								id={formState.mobile_number.id}
								label={formState.mobile_number.label}
								type='tel'
								placeholder={formState.mobile_number.placeholder}
								value={formState.mobile_number.value}
								pattern='^\d{7}\d+$'
								maxLength={10}
								onChange={updateState('mobile_number', 'value')}
								required={formState.mobile_number.required}
								errorMap={{
									patternMismatch: `${strings('enter_valid_num')}`,
									valueMissing: `${strings('mob_num_required')}`,
								}}
								disabled={isEdit}
								hasError={(error) => {
									setFormState((_s) => ({
										..._s,
										mobile_number: {
											..._s['mobile_number'],
											valid: !error,
										},
									}))
								}}
							/>
						</BiInputWrapper>
					</InputWrapper>
					<InputWrapper>
						<Input
							id={formState.email.id}
							label={formState.email.label}
							type='email'
							pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
							errorMap={{
								patternMismatch: `${strings('enter_valid_email')}`,
							}}
							placeholder={formState.email.placeholder}
							value={formState.email.value}
							onChange={updateState('email', 'value')}
							required={formState.email.required}
						/>
					</InputWrapper>
					<InputWrapper>
						<BiInputWrapper>
							<Select
								id={formState.region?.id}
								label={formState.region?.label}
								data={formState.region?.data}
								value={formState.region?.value}
								onChange={updateState('region', 'value')}
								placeholder={formState?.region?.placeholder}
								displayKey={formState?.region?.displayKey}
								primaryKey={formState?.region?.primaryKey}
							/>
							{panByCompanyName?.length > 0 ? (
								<Select
									id={formState.pan.id}
									label={formState.pan.label}
									placeholder={strings(['select', 'pan', 'number'])}
									data={panByCompanyName}
									displayKey='display_name'
									primaryKey='pan'
									value={panByCompanyName?.find((item) => item?.pan === formState.pan?.value?.pan) ?? (formState.pan.value || {})}
									onChange={(val) => {
										updateState('pan', 'value')(val)
									}}
									createNewOption={true}
									maxLength='10'
									uppercase
									required={formState.pan.required}
								/>
							) : (
								<Input
									id={formState.pan.id}
									label={formState.pan.label}
									type='text'
									placeholder={formState.pan.placeholder}
									value={formState.pan.value}
									maxLength='10'
									uppercase
									pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
									errorMap={{
										patternMismatch: strings('enter_valid_pan'),
									}}
									onChange={updateState('pan', 'value')}
									required={formState.pan.required}
								/>
							)}
						</BiInputWrapper>
					</InputWrapper>
				</SectionItemWrapper>
				<SectionItemWrapper>
					<Input
						id={formState.organisation_name.id}
						label={formState.organisation_name.label}
						type='text'
						placeholder={formState.organisation_name.placeholder}
						value={formState.organisation_name.value}
						onChange={updateState('organisation_name', 'value')}
						required={formState.organisation_name.required}
					/>

					<InputWrapper>
						<BiInputWrapper>
							<Calendar
								label={formState.closure_date.label}
								id={formState.closure_date.id}
								value={formState.closure_date.value}
								onChange={updateState('closure_date', 'value')}
							/>
							<Select
								label={formState.source.label}
								id={formState.source.id}
								placeholder={formState.source.placeholder}
								value={formState.source.value}
								onChange={updateState('source', 'value')}
								data={formState.source.data}
								displayKey={formState.source.displayKey}
								primaryKey={formState.source.primaryKey}
								openBelow={false}
								required={formState.source.required}
							/>
						</BiInputWrapper>
					</InputWrapper>
					<InputWrapper>
						<BiInputWrapper>
							<Input
								id={formState.location.id}
								label={formState.location.label}
								type='text'
								placeholder={formState.location.placeholder}
								value={formState.location.value}
								onChange={updateState('location', 'value')}
								required={formState.location.required}
							/>
							<Input
								id={formState.turnover.id}
								label={formState.turnover.label}
								type='number'
								placeholder={formState.turnover.placeholder}
								value={formState.turnover.value}
								onChange={updateState('turnover', 'value')}
								required={formState.turnover.required}
							/>
						</BiInputWrapper>
					</InputWrapper>
				</SectionItemWrapper>
				<SectionItemWrapper>
					<FormHeading>{strings('organisation', 'details')}</FormHeading>
					<InputWrapper>
						<Select
							label={formState.category.label}
							id={formState.category.id}
							placeholder={formState.category.placeholder}
							value={formState.category.value}
							onChange={updateState('category', 'value')}
							data={formState.category.data}
							displayKey={formState.category.displayKey}
							primaryKey={formState.category.primaryKey}
							openBelow={false}
							required={formState.category.required}
						/>
					</InputWrapper>
					<InputWrapper>
						<Select
							label={formState.industry.label}
							id={formState.industry.id}
							placeholder={formState.industry.placeholder}
							value={formState.industry.value}
							onChange={updateState('industry', 'value')}
							data={formState.industry.data}
							displayKey={formState.industry.displayKey}
							primaryKey={formState.industry.primaryKey}
							openBelow={false}
						/>
					</InputWrapper>
				</SectionItemWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				{isEdit ? (
					<Button small uppercase type='primary' disabled={disabled} onClick={editLeadHandler}>
						{strings('edit', 'lead')}
					</Button>
				) : (
					<Button small uppercase type='primary' disabled={disabled} onClick={addLeadHandler}>
						{strings('add', 'lead')}
					</Button>
				)}
			</DrawerFooterWrapper>
		</>
	)
}

export default AddLead
