import React from 'react'
import { FormWrapper, SectionItemWrapper } from '../../styles'
import { Input, Select } from '@base'
import { strings } from '@constants'

const commodityTypes = [
	{ key: 'FERROUS', label: 'FERROUS' },
	{ key: 'NON_FERROUS', label: 'NON FERROUS' },
	{ key: 'OTHERS', label: 'OTHERS' },
]

const CommodityForm = React.memo(({ state, onCommodityChange }) => {
	const { commodity_name, commodity_type } = state

	let selectedCommodity = commodityTypes.find((cls) => cls.key === commodity_type) || {}

	const onChange = (key) => (value) => {
		onCommodityChange(key, key === 'commodity_name' ? value?.toUpperCase() : value)
	}

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Input
					type='text'
					label={strings('name')}
					value={commodity_name}
					onChange={onChange('commodity_name')}
					placeholder={strings('msg_no_special_char')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Select
					id='type'
					disabled={false}
					label={strings('type')}
					displayKey={'label'}
					primaryKey={'key'}
					placeholder={strings('select_type')}
					data={commodityTypes}
					value={selectedCommodity || {}}
					onChange={onChange('commodity_type')}
				/>
			</SectionItemWrapper>
		</FormWrapper>
	)
})

export default CommodityForm
