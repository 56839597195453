import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { updatePaymentTenureAndTerms } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { InputWrapper, Label } from '@pages/Enquiry/CreateEnquiry/styles'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

const deriveFormState = (paymentTerms, paymentTenure, lcTenure) => {
	const _state = {
		advance: {
			id: 'advance',
			value: '',
		},
		lc: {
			id: 'lc',
			value: '',
		},
		pod: {
			id: 'pod',
			value: '',
		},
		credit: {
			id: 'credit',
			value: '',
		},
		payment_tenure: {
			id: 'payment-tenure',
			value: '',
			disabled: false,
			required: false,
		},
		lc_tenure: {
			id: 'lc-tenure',
			label: strings('lc', 'tenure'),
			value: '',
			disabled: false,
			required: false,
		},
	}
	if (paymentTerms) {
		_state.advance.value = paymentTerms?.advance ? parseInt(paymentTerms?.advance) : ''
		_state.credit.value = paymentTerms?.credit ? parseInt(paymentTerms?.credit) : ''
		_state.lc.value = paymentTerms?.lc ? parseInt(paymentTerms?.lc) : ''
		_state.pod.value = paymentTerms?.pod ? parseInt(paymentTerms?.pod) : ''
	}
	if (paymentTenure) {
		_state.payment_tenure.value = parseInt(paymentTenure)
	}
	if (lcTenure) {
		_state.lc_tenure.value = lcTenure
	}
	return _state
}

const PaymentTerms = ({ data, update }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveFormState())

	useEffect(() => {
		if (data) {
			let _paymentTerms = {}
			;(data?.buyer_list?.[0]?.payment_terms?.split('|') || [])?.map((item) => {
				const obj = item.split(':')
				_paymentTerms = { ..._paymentTerms, [obj[0]]: obj[1] || '' }
			})
			let paymentTenure = parseInt(data?.buyer_list?.[0]?.payment_tenure)
			let lcTenure = data?.buyer_list?.[0]?.lc_tenure
			setFormState(deriveFormState(_paymentTerms, paymentTenure, lcTenure))
		}
	}, [data])

	const updateState = (key) => (value) => {
		if (key === 'credit' && (!value || value === 0)) {
			updateState('payment_tenure')('')
		}
		if (key === 'lc' && (!value || value === 0)) {
			updateState('lc_tenure')('')
		}
		setFormState((_s) => ({
			..._s,
			[key]: { ..._s[key], value: value },
		}))
	}

	const updateDisabled = useMemo(() => {
		if (
			(parseInt(formState?.advance?.value) || 0) +
				(parseInt(formState?.lc?.value) || 0) +
				(parseInt(formState?.credit?.value) || 0) +
				(parseInt(formState?.pod?.value) || 0) !==
				100 ||
			formState?.payment_tenure?.value > 90
		) {
			return true
		}

		return false
	}, [formState])

	const updateHandler = () => {
		let _paymentTerms = [
			`advance:${formState?.advance?.value === 0 ? '' : formState?.advance?.value}`,
			`lc:${formState?.lc?.value === 0 ? '' : formState?.lc?.value}`,
			`pod:${formState?.pod?.value === 0 ? '' : formState?.pod?.value}`,
			`credit:${formState?.credit?.value === 0 ? '' : formState?.credit?.value}`,
		].join('|')

		if (formState?.credit?.value !== '' && !formState?.payment_tenure?.value) {
			dispatch(showToast(true, strings('msg_enter_payment_tenure_error'), { type: TOAST_TYPE.ERROR }))
			return
		}
		if (formState?.lc?.value !== '' && !formState?.lc_tenure?.value) {
			dispatch(showToast(true, strings('msg_enter_lc_tenure_error'), { type: TOAST_TYPE.ERROR }))
			return
		}

		dispatch(showLoader(true))
		updatePaymentTenureAndTerms(data?.order_id, _paymentTerms, formState?.payment_tenure?.value, formState?.lc_tenure?.value)
			.then((response) => {
				dispatch(showToast(true, strings('msg_order_editted'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				dispatch(showToast(true, error?.message || strings('msg_order_update_fail'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
				dispatch(showLoader(false))
				update()
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<InputWrapper>
					<Input id={formState?.advance?.id} type='number' max='100' value={formState?.advance?.value} onChange={updateState('advance')} />
					<Label htmlFor='advance-checkbox-%' disabled>
						%
					</Label>
					<Label htmlFor='advance-checkbox' disabled>
						{strings('advance')}
					</Label>
				</InputWrapper>
				<InputWrapper TwoInput>
					<div>
						<Input id='select-payment-lc' type='number' max='100' value={formState?.lc?.value} onChange={updateState('lc')} />
						<Label htmlFor='lc-checkbox-%' disabled>
							%
						</Label>
						<Label htmlFor='lc-checkbox' disabled>
							{strings('lc')}
						</Label>
					</div>
					<div>
						<Input
							label={formState?.lc_tenure?.label}
							id={formState?.lc_tenure?.id}
							type='number'
							value={formState?.lc_tenure?.value}
							onChange={updateState('lc_tenure')}
							endOfDay={false}
							disabled={!formState?.lc?.value}
						/>
					</div>
				</InputWrapper>
				<InputWrapper>
					<Input id='select-payment-pod' type='number' max='100' value={formState?.pod?.value} onChange={updateState('pod')} />
					<Label htmlFor='payment-on-delivery-checkbox-%' disabled>
						%
					</Label>
					<Label htmlFor='payment-on-delivery-checkbox' disabled>
						{strings('payment_delivery')}
					</Label>
				</InputWrapper>
				<InputWrapper TwoInput>
					<div>
						<Input id='credit-for' type='number' max='100' min='0' value={formState?.credit?.value} onChange={updateState('credit')} />
						<Label htmlFor='credit-for-%' disabled>
							%
						</Label>
						<Label htmlFor='credit-for' disabled>
							{strings('credit')}
						</Label>
					</div>
					<div>
						<Input
							id='select-payment-tenure'
							type='number'
							max='90'
							label='Payment Tenure (Days)'
							disabled={formState.credit.value === ''}
							value={formState?.payment_tenure?.value}
							onChange={updateState('payment_tenure')}
							placeholder='Enter payment tenure'
							errorMap={{
								patternMismatch: strings('enter_payment_tenure'),
							}}
						/>
					</div>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small disabled={updateDisabled} onClick={updateHandler}>
					{strings('update')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default PaymentTerms
