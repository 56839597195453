import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { DrawerBodyWrapper, DrawerFooterWrapper, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { showToast, showLoader } from '@data/state/action'

import CreateItemForm from './CreateItemForm'
import { Footer, ActionButton } from '../styles'
import { createCategory, editCategory, getAllCommodityApi } from '@data'
import { Category as CategoryModel } from '@data/model'
import { paths } from '@routes'

const CreateCategory = ({ data, update }) => {
	const edit = !!data
	const [category, setCategory] = useState(new CategoryModel())
	const [getCommodity, setGetCommodity] = useState()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		getAllCommodityApi().then((res) => {
			setGetCommodity(res)
		})
		if (data) {
			const newData = {}
			Object.entries(data?.category_attributes || {})
				?.filter(([, value]) => value === true || value)
				?.forEach(([key, value]) => (newData[key] = data?.popular_attributes ? data?.popular_attributes[key]?.toString() || value : value))
			setCategory({ ...data, popular_attributes: { ...newData } })
		} else {
			setCategory({ ...category, popular_attributes: { length: true } })
		}
	}, [data])

	const onCategoryChange = (key, value) => {
		const tempCategory = Object.assign({}, category)

		if (key === 'category_name') {
			tempCategory['category_name'] = value
			if (!edit) {
				tempCategory['category_id'] = value?.toLowerCase().trim().replace(/ /g, '_')
				tempCategory['slug'] = value?.toLowerCase().trim().replace(/ /g, '-')
			}
		} else if (key === 'category_id') {
			tempCategory['category_id'] = value?.toLowerCase().trim().replace(/ /g, '_')
			tempCategory['slug'] = value?.toLowerCase().trim().replace(/ /g, '-')
		} else if (key === 'top') {
			tempCategory['top'] = !tempCategory[key]
		} else if (key === 'preferred_units') {
			tempCategory['preferred_units'] = value.map((item) => item.name)
		} else if (key === 'popular_attributes') {
			tempCategory['popular_attributes'] = { ...category?.popular_attributes, ...value }
		} else if (key === 'thickness_first') {
			tempCategory['thickness_first'] = value.target.checked
		} else {
			tempCategory[key] = value
		}
		if (key === 'gst' && !tempCategory.igst) {
			tempCategory['igst'] = value
		}
		setCategory(tempCategory)
	}

	const handleAttributeChange = (attribute) => {
		const tempCategory = Object.assign({}, category)
		tempCategory.category_attributes = {
			...tempCategory.category_attributes,
			[attribute]: !tempCategory.category_attributes[attribute],
		}

		const newData = {}

		Object.entries(tempCategory.category_attributes)
			.filter(([, value]) => value === true)
			.forEach(([key, value]) => (newData[key] = value))

		tempCategory.popular_attributes = {
			...newData,
			...tempCategory.popular_attributes,
			[attribute]: [attribute] in tempCategory.popular_attributes ? (tempCategory.popular_attributes[attribute] === null ? true : null) : true,
		}
		setCategory(tempCategory)
	}

	const additionInfoHandler = () => {
		navigate(paths.admin_catalog_edit_category(category?.category_id))
	}

	const disableButton = !category?.category_name

	const renderButtons = () => {
		if (!edit) {
			return (
				<ActionButton small type='primary' disabled={disableButton} onClick={addNewItemHandler}>
					{strings('add')}
				</ActionButton>
			)
		} else {
			return (
				<>
					<ActionButton small disabled={disableButton} type='primary' onClick={updateHandler}>
						{strings('update')}
					</ActionButton>
					<ActionButton small type='tertiary' onClick={cancelEditHandler}>
						{strings('cancel')}
					</ActionButton>
					<ActionButton small type='secondary' onClick={additionInfoHandler} margin={`0 auto 0 0`}>
						{strings('additional', 'info')}
					</ActionButton>
				</>
			)
		}
	}

	const checkIfCategoryValid = () => {
		const hasAttributes = Object.values(category.category_attributes ?? {}).some((_val) => _val)

		if (!hasAttributes) {
			dispatch(showToast(true, strings('msg_atleast_one_ctgy_attri_must_be_selected'), { type: TOAST_TYPE.ERROR }))
			return false
		}

		return true
	}

	const categoryDataAttributes = () => {
		let popular_attributes = {}
		Object.keys(category?.popular_attributes).map((key) => {
			popular_attributes = {
				...popular_attributes,
				[key]:
					typeof category?.popular_attributes[key] === 'string' && category?.popular_attributes[key] !== ''
						? category?.popular_attributes[key]
								?.split(',')
								.filter((item) => item !== '' && item)
								?.map(key !== 'custom_details' ? Number : String) || null
						: null,
			}
		})
		return { ...category, popular_attributes }
	}

	const addNewItemHandler = () => {
		if (checkIfCategoryValid()) {
			dispatch(showLoader(true))
			createCategory(categoryDataAttributes())
				.then((_) => {
					dispatch(toggleDrawer(false))
					dispatch(showToast(true, strings('msg_category_created'), { type: TOAST_TYPE.SUCCESS }))
					update()
				})
				.catch((err) => {
					dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}

	const updateHandler = () => {
		if (checkIfCategoryValid()) {
			dispatch(showLoader(true))
			editCategory(categoryDataAttributes(), data?.category_id)
				.then((_) => {
					dispatch(toggleDrawer(false))
					dispatch(showToast(true, strings('msg_category_editted'), { type: TOAST_TYPE.SUCCESS }))
					update()
				})
				.catch((err) => {
					dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}

	const cancelEditHandler = () => {
		dispatch(toggleDrawer(false))
	}

	return (
		<>
			<DrawerBodyWrapper>
				<CreateItemForm
					state={category}
					onCategoryChange={onCategoryChange}
					handleAttributeChange={handleAttributeChange}
					getCommodity={getCommodity}
				/>
			</DrawerBodyWrapper>
			<Footer>{renderButtons()}</Footer>
		</>
	)
}

export default CreateCategory
