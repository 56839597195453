import React from 'react'
import { Checkbox, Input, MultiSelect, Select, Textarea } from '@base'
import { GST_PERCENTAGE_VALUES, strings } from '@constants'
import {
	FormWrapper,
	SectionItemWrapper,
	FormElementLabel,
	MBCheckboxContainer,
	MBCheckboxMain,
	FormHeading,
	SectionItemWrapperWithTwoItems,
} from '../styles'
import { capitalizeWord } from '@utils'

const _brands = [{ name: 'WEIGHT' }, { name: 'LENGTH' }, { name: 'PIECE' }]
const gst_percentage_list = Object.entries(GST_PERCENTAGE_VALUES).map(([key, value]) => ({ key, label: value }))
const categoryType = [
	{ key: 'LONG', label: 'LONG' },
	{ key: 'FLAT', label: 'FLAT' },
]

const CreateItemForm = React.memo(({ state, onCategoryChange, handleAttributeChange, getCommodity }) => {
	const {
		category_name,
		category_id,
		commodity,
		thumbnail_url,
		description,
		hsn_code,
		slug,
		gst,
		igst,
		category_attributes,
		priority,
		preferred_units,
		popular_attributes,
		thickness_first,
		type,
	} = state

	let selectedbrands = preferred_units?.length > 0 ? _brands.filter((cls) => preferred_units.includes(cls.name)) : []

	let popularAttributeItems = popular_attributes ? Object.entries(popular_attributes)?.filter((item, i) => item[1] !== null) : []

	const onChange = (key) => (value) => {
		onCategoryChange(key, value)
	}

	const onChangeKey = (key) => (value) => {
		onCategoryChange('popular_attributes', { [key]: value })
	}

	const onhandleAttributeChange = (attribute) => {
		handleAttributeChange(attribute)
	}

	const popularAttributesUi = () => {
		return (
			<>
				{popularAttributeItems.map((item, index) => {
					if (item[0] !== null)
						return (
							<SectionItemWrapper key={index}>
								<Input
									type='text'
									label={capitalizeWord(strings(item[0]))}
									value={popular_attributes[item[0]] === true ? '' : popular_attributes[item[0]]}
									onChange={onChangeKey(item[0])}
									placeholder={capitalizeWord(strings(item[0]))}
								/>
							</SectionItemWrapper>
						)
				})}
			</>
		)
	}

	const renderAttributeCheckBoxes = () => (
		<MBCheckboxContainer>
			{Object.keys(category_attributes).map((attribute, index) => (
				<MBCheckboxMain key={`${attribute}-${index}`}>
					<Checkbox
						id={attribute}
						size={1.125}
						checked={category_attributes[attribute]}
						onChange={() => onhandleAttributeChange(attribute)}
					/>
					<FormElementLabel htmlFor={attribute}>{capitalizeWord(strings(attribute))}</FormElementLabel>
				</MBCheckboxMain>
			))}
		</MBCheckboxContainer>
	)

	return (
		<FormWrapper>
			<SectionItemWrapper>
				<Input
					type='text'
					label={strings('category_name')}
					value={category_name}
					onChange={onChange('category_name')}
					placeholder={strings('msg_no_special_char')}
				/>
				<Input type='text' label={strings('category_id')} disabled={true} value={category_id} onChange={onChange('category_id')} />
			</SectionItemWrapper>

			<SectionItemWrapper>
				<Select
					label={strings('select', 'type')}
					data={categoryType}
					displayKey='label'
					primaryKey='key'
					value={categoryType.find((item) => item.key === type) || {}}
					onChange={(value) => onChange('type')(value.key)}
				/>
				<Select
					label={strings('select', 'commodity')}
					data={getCommodity}
					displayKey='commodity_name'
					primaryKey='commodity_name'
					value={commodity}
					onChange={(value) => onChange('commodity')(value)}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Input type='text' label={strings('hsn_code')} disabled={false} value={hsn_code} onChange={onChange('hsn_code')} />
				<Input
					type='text'
					pattern='^[0-9]*$'
					label={strings('priority')}
					value={priority}
					onChange={onChange('priority')}
					errorMap={{
						patternMismatch: strings('enter_valid_num'),
					}}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Select
					label={strings(['gst', '(', '%', ')'])}
					data={gst_percentage_list}
					displayKey='key'
					primaryKey='label'
					value={gst_percentage_list.find((item) => item.label === gst) ?? {}}
					onChange={(value) => onChange('gst')(value.label)}
				/>
				<Select
					label={strings(['igst', '(', '%', ')'])}
					data={gst_percentage_list}
					displayKey='key'
					primaryKey='label'
					value={gst_percentage_list.find((item) => item.label === igst) ?? {}}
					onChange={(value) => onChange('igst')(value.label)}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Input type='text' label={strings('slug')} value={slug} onChange={onChange('slug')} />
			</SectionItemWrapper>

			<FormHeading>{strings('select', 'attributes')}</FormHeading>
			<SectionItemWrapper>{renderAttributeCheckBoxes()}</SectionItemWrapper>

			<SectionItemWrapper>
				<MultiSelect
					id='preferred_units'
					disabled={false}
					label={'Preferred Units'}
					displayKey={'name'}
					primaryKey={'name'}
					placeholder={['Select Preferred Units']}
					data={_brands}
					value={selectedbrands}
					onChange={onChange('preferred_units')}
					multiple
				/>
			</SectionItemWrapper>

			<SectionItemWrapperWithTwoItems>
				<Checkbox
					id='thickness_first_checkbox'
					size={1.125}
					checked={thickness_first}
					value={thickness_first}
					onChange={onChange('thickness_first')}
				/>
				<FormElementLabel htmlFor='thickness_first_checkbox'>{strings('thicknesss', 'first')}</FormElementLabel>
			</SectionItemWrapperWithTwoItems>

			{popularAttributesUi()}
			<SectionItemWrapper>
				<Textarea label={strings('description')} value={description} onChange={onChange('description')} />
			</SectionItemWrapper>
		</FormWrapper>
	)
})
export default CreateItemForm
