import styled, { css, keyframes } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { H5, Button, H6 } from '@base'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	min-width: 100%;
`

export const BaseWrapper = styled.div`
	padding: 0 ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		margin-bottom: ${Spacings.SPACING_4B};
	}
`

export const CreateEnquiryWrapper = styled(BaseWrapper)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export const PsuedoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`

export const CreateEnquiryFooter = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
	position: relative;
`

export const ButtonsWrapper = styled.div`
	display: flex;
	margin: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
`

export const PaddedWrapper = styled.div`
	padding: ${Spacings.SPACING_2B} 0;
`

export const PillsWrapper = styled(PaddedWrapper)`
	padding-bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start @media ${Devices.mobile} {
		align-items: center;
	}
`

export const StepWrapper = styled(PaddedWrapper)``
export const SectionBody = styled.div`
	display: flex;
`
export const ItemsWrapper = styled.div`
	flex-grow: 1;
`

export const ItemWrapper = styled.div`
	flex-grow: 1;
	margin-bottom: ${Spacings.SPACING_5B};
`
export const QuotePickerWrapper = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_4B} ${Spacings.SPACING_2B};
	flex-wrap: wrap;
`
export const QuoteItemWrapper = styled.div`
	width: calc(50% - 0.5rem);
	&:first-of-type {
		flex-grow: 1;
		width: 100%;
	}

	&:nth-of-type(2) {
		margin-right: ${Spacings.SPACING_4B};
	}

	${({ isbrand }) => {
		if (isbrand) {
			return css`
				display: flex;
			`
		}
	}}

	${({ isTcAvailable }) => {
		if (isTcAvailable) {
			return css`
				display: flex;
				justify-content: flex-start;
				flex-direction: row;
				overflow-wrap: anywhere;
				align-items: center;
				margin: 10px 0px;
			`
		}
	}}


@media ${Devices.mobile} {
		justify-content: center;
		${({ isTcAvailable }) => {
			if (isTcAvailable) {
				return css`
					justify-content: flex-start;
				`
			}
		}}
	}

	@media ${Devices.tablet} {
		width: min-content;
		&:first-of-type {
			width: min-content;
			flex: 1;
		}
		flex-grow: 1;
		margin-right: ${Spacings.SPACING_4B};
		&:last-of-type {
			margin-right: 0;
		}

		${({ isbrand }) => {
			if (isbrand) {
				return css`
					display: none;
				`
			}
		}}

		${({ isTcAvailable }) => {
			if (isTcAvailable) {
				return css`
					display: none;
					margin: 0px;
				`
			}
		}}
	}

	@media ${Devices.laptop} {
		${({ isServiceableWeight }) => {
			if (isServiceableWeight) {
				return css`
					&:first-of-type {
						flex-grow: 1;
						width: 100%;
						flex: 1;
					}
				`
			}
		}}
	}

	@media ${Devices.desktop} {
		&:first-of-type {
			flex-grow: 1;
			width: 100%;
			flex: 2;
		}

		${({ isbrand }) => {
			if (isbrand) {
				return css`
					display: flex;
				`
			}
		}}
		${({ isTcAvailable }) => {
			if (isTcAvailable) {
				return css`
					display: flex;
				`
			}
		}}
	}
`
export const QuotePickerWrapperLaptop = styled.div`
	display: none;
	padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_4B} ${Spacings.SPACING_2B};
	flex-wrap: wrap;

	@media ${Devices.mobile} {
		display: none;
	}

	@media ${Devices.tablet} {
		display: flex;
	}

	@media ${Devices.desktop} {
		display: none;
	}
`

export const QuoteItemWrapperCheckBox = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 1;
`
export const QuoteItemWrapperSelect = styled.div`
	flex: 1;
	margin-right: 4rem;
`
export const ActionButton = styled(Button)``

export const BackButton = styled(Button)`
& > svg {
    margin-right ${Spacings.SPACING_1B};
}
`
export const TablerButton = styled(Button)`
	padding: 0 ${Spacings.SPACING_2B};
	min-width: ${Spacings.SPACING_4B};
`

export const bounce = keyframes`
0% {
    padding-bottom: 0;
}
20% {
    padding-bottom: 4px;
}
30% {
    padding-bottom: 8px;
}
40% {
    padding-bottom: 12px;
}
50% {
    padding-bottom: 16px;
}
60% {
    padding-bottom: 12px;
}
70% {
    padding-bottom: 8px;
}
80% {
    padding-bottom: 4px;
}
100% {
    padding-bottom: 0;
}

`

export const MobileAmountSection = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);

	@media ${Devices.tablet} {
		display: none;
	}
`
export const DesktopAmountSection = styled.div`
	display: none;
	@media ${Devices.tablet} {
		display: initial;
		min-width: 17rem;
		width: 17rem;
		margin-left: ${Spacings.SPACING_6B};
		border-radius: ${Spacings.SPACING_2B};
		height: fit-content;
	}
`

export const TotalAmountSection = styled.div`
	padding: ${Spacings.SPACING_3B};
	display: flex;
	user-select: none;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		padding: 0 0 ${Spacings.SPACING_4B} 0;
	}
`
export const InfoPanel = styled.div`
	min-height: ${({ show }) => (show ? '18rem' : '0')};
	max-height: ${({ show }) => (show ? '28rem' : '0')};
	border-bottom: 1px solid ${({ show, theme }) => (show ? theme.palette.background.searchgrey : theme.palette.background.white)};

	transition: all 0.15s ease-in-out;
	overflow-y: scroll;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	padding: ${({ show }) => (show ? `${Spacings.SPACING_4B} ${Spacings.SPACING_3B}` : `0 ${Spacings.SPACING_3B}`)};

	${({ amountPanelAnimation }) => {
		if (amountPanelAnimation) {
			return css`
				animation-name: ${bounce};
				animation-duration: 0.8s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in;
				animation-fill-mode: forwards;
			`
		}
	}}

	@media ${Devices.tablet} {
		min-height: fit-content;
		max-height: fit-content;
		padding: ${Spacings.SPACING_4B} 0;
		border: none;
		animation: none;
	}
`

export const Label = styled.span`
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.label};
	display: inline-block;
	margin-bottom: ${Spacings.SPACING_2B};
`

export const FlexWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const PartialAmountWrapper = styled(FlexWrapper)`
	padding: ${Spacings.SPACING_2B};
	border-radius: ${Spacings.SPACING_2B};
	background: ${({ theme }) => theme.palette.background.pill};
	margin-top: ${({ margin }) => margin || Spacings.SPACING_2B};
`

export const TotalAmountWrapper = styled(FlexWrapper)`
	align-items: flex-end;
	margin-left: auto;
`

export const IconWrapper = styled(FlexWrapper)`
	align-items: center;
	justify-content: center;
	padding: ${Spacings.SPACING_2B} 0 ${Spacings.SPACING_2B} ${Spacings.SPACING_2B};
	margin-left: ${Spacings.SPACING_3B};
	border-left: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	cursor: pointer;
`

export const Amount = styled.span`
	display: flex;
	flex-wrap: wrap;
	font-weight: ${Text.BOLD_600};
	line-height: ${Text.LINE_HEIGHT_MAX};
`

export const Sign = styled.div`
	font-size: ${Text.MEDIUM};
	font-weight: ${Text.BOLD_600};
	margin-top: ${Spacings.SPACING_2B};
	text-align: center;
`

export const Heading = styled(H5)`
	@media ${Devices.tablet} {
		margin-bottom: ${Spacings.SPACING_3B};
	}
`

export const SingleItemWrapper = styled.div`
	max-width: 20rem;
	padding-top: ${Spacings.SPACING_1B};
`

export const CheckboxWrapper = styled.div`
	display: flex;
	padding: ${Spacings.SPACING_3B};
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_10B};
	}
`

export const CheckboxLabel = styled.label`
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.label};
	display: inline-block;
	margin-left: ${Spacings.SPACING_2B};
	cursor: pointer;
`

export const SupplierSelectWrapper = styled.div`
	width: 100%;
	@media ${Devices.tablet} {
		width: 50%;
	}
`

export const CardSpan = styled(H6)`
	line-height: ${Text.LINE_HEIGHT_MAX};
	margin: ${Spacings.SPACING_1B} 0px 0px ${Spacings.SPACING_1B};
	font-size: ${Text.EXTRA_SMALL};
`
export const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
