import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button, H4, H5, NavigationWrapper } from '@base'

export const CatalogNavigationWrapper = styled(NavigationWrapper)`
	display: flex;
	align-items: center;
`

export const SearchWrapper = styled.div`
	padding-left: ${Spacings.SPACING_4B};
	margin-left: auto;
`

export const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`

export const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`
export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	margin-top: ${Spacings.SPACING_2B};
	@media ${Devices.tablet} {
		flex-direction: row;
	}
	& > div {
		width: 100%;
	}
`

export const SectionItemWrapperWithTwoItemsInRow = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	& > div {
		flex-basis: calc(50% - ${Spacings.SPACING_2B});
		&:nth-child(2n + 1) {
			margin-right: ${Spacings.SPACING_2B};
		}
		&:nth-child(2n) {
			margin-left: ${Spacings.SPACING_2B};
		}
	}
`

export const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

export const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

export const FormElementLabel = styled.label`
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	color: ${({ theme }) => theme.palette.text.label};
	margin-left: ${Spacings.SPACING_2B};
	cursor: pointer;
	user-select: none;
`

export const MBCheckboxContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	& > div {
		flex-basis: 50%;
	}
	@media ${Devices.tablet} {
		& > div {
			flex-basis: 33%;
		}
	}
`
export const MBCheckboxMain = styled.div`
	display: flex;
	alignitems: center;
	margin: 10px 0px;
`

export const SectionHeading = styled(H4)`
	padding-bottom: ${Spacings.SPACING_3B};
	border-bottom: ${({ theme, noBorder }) => (noBorder ? 'none' : `1px solid ${theme.palette.background.searchgrey}`)};
	margin: ${({ margin }) => margin};
	display: flex;
	align-items: center;
`

export const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_1B};
	${({ isAttribute }) => {
		if (isAttribute) {
			return css`
				& > div {
					display: flex;
				}
				display: flex;
				width: 100%;
				justify-content: space-between;
			`
		}
	}}
`
export const FormItemWrapper = styled.div`
	margin-top: ${Spacings.SPACING_2B};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	&:first-of-type {
		margin-top: 0;
	}
	& > div {
		width: 100%;
		flex: 1;
	}
`
