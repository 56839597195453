import styled, { css } from 'styled-components'
import { Devices, Spacings, Text } from '@styles'
import { Button, H5 } from '@base'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	flex-grow: 1;
`

export const BaseWrapper = styled.div`
	padding: 0 ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	@media ${Devices.tablet} {
		border-radius: ${Spacings.SPACING_2B};
		margin-bottom: ${Spacings.SPACING_4B};
	}
`

export const CreateEnquiryWrapper = styled(BaseWrapper)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

export const PsuedoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`

export const CreateEnquiryFooter = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction:  ${({ isCalculator }) => (isCalculator ? 'row' : 'column')}};
	justify-content: ${({ isCalculator }) => (isCalculator ? 'space-between' : 'flex-start')}};
`

export const ButtonsWrapper = styled.div`
	display: flex;
	margin-top: ${Spacings.SPACING_3B};
`

export const FormSection = styled.div`
	padding: ${Spacings.SPACING_4B} 0 ${Spacings.SPACING_4B};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	&:first-of-type {
		display: flex;
		flex-direction: column;
		flex-grow: ${({ flexGrow }) => (flexGrow ? 1 : 0)};
	}
	&:last-of-type {
		border: none;
		margin-bottom: ${Spacings.SPACING_4B};
	}
`
export const HeaderWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	user-select: none;
	align-items: flex-start;
	${({ isItems }) => {
		if (isItems) {
			return css`
				margin-bottom: auto;
				@media ${Devices.mobile} {
					margin-bottom: ${Spacings.SPACING_3B};
				}
			`
		} else {
			return css`
				width: 100%;
			`
		}
	}}
`

export const AddedItemsList = styled.div``

export const AddedCategoryItemsWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: ${Spacings.SPACING_4B};
	& > span {
		margin-bottom: ${Spacings.SPACING_2B};
	}
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
`

export const AddItemButton = styled(Button)`
	margin-left: auto;
`

export const BaseButton = styled(Button)``

export const RightPositionedButton = styled(BaseButton)`
	margin-left: ${({ noLeftAuto }) => (noLeftAuto ? 0 : 'auto')};
	@media ${Devices.tablet} {
		margin-left: ${({ leftAuto }) => (leftAuto ? 'auto' : Spacings.SPACING_4B)}};
	}
`

export const ContinueButton = styled(RightPositionedButton)`
	& > svg {
		margin-left: ${Spacings.SPACING_1B};
	}
`
export const EndStepButton = styled(RightPositionedButton)``

export const BackButton = styled(BaseButton)`
	& > svg {
		margin-right: ${Spacings.SPACING_1B};
	}
`

export const CancelUpdateButton = styled(BaseButton)`
	display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'initial')};
	margin-left: ${({ leftAuto }) => (leftAuto ? 'auto' : '')}};
	& > span {
		display: initial;
	}
	& > svg {
		margin-left: ${Spacings.SPACING_1B};
	}
	@media ${Devices.tablet} {
		margin-left: auto;
		display: initial;
	}
`
export const AddNewAddressButton = styled(BaseButton)``
export const ChangeAddressButton = styled(BaseButton)``

export const AddressListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${Devices.tablet} {
		flex-direction: row;
		flex-wrap: wrap;
	}
`
export const AddressCardWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_4B};
	flex-grow: 1;

	border: 1px solid transparent;
	border-radius: ${Spacings.SPACING_2B};
	transition: border-color 0.15s ease-in-out;
	${({ selected }) => {
		if (selected) {
			return css`
				cursor: initial;
				border-color: ${({ theme }) => theme.palette.background.blue};
			`
		}
	}}

	${({ hoverable = true }) => {
		if (hoverable) {
			return css`
				cursor: pointer;
				&:hover {
					border-color: ${({ theme }) => theme.palette.background.blue};
				}
			`
		}
	}}

	@media ${Devices.tablet} {
		flex: 1 1 calc(50% - ${Spacings.SPACING_2B});
		max-width: calc(50% - ${Spacings.SPACING_2B});
		&:nth-child(2n + 1) {
			margin-right: ${Spacings.SPACING_4B};
		}
	}
`

export const StepperWrapper = styled.div`
	padding-bottom: ${Spacings.SPACING_3B};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
`

export const DateWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	@media ${Devices.mobile} {
		max-width: 26rem;
	}
	margin-top: ${Spacings.SPACING_1B};
`

export const OpenTillWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
`
export const ShareEnquiryWapper = styled.div`
	display: flex;
	flex-direction: column;
	div {
		display: flex;
	}
	div:nth-child(2) {
		margin: 10px 0px;
	}
`
export const FormElementLabel = styled.label`
	font-size: 0.75rem;
	display: block;
	margin-left: 8px;
	color: ${({ disabled, theme }) => (disabled ? theme.palette.text.greydark : theme.palette.text.black)};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

export const AddedItemsInfoWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: ${Spacings.SPACING_2B};
	row-gap: ${Spacings.SPACING_4B};

	@media ${Devices.tablet} {
		grid-template-columns: repeat(5, 1fr);
	}
	@media ${Devices.laptop} {
		grid-template-columns: repeat(6, 1fr);
	}
	@media ${Devices.desktop} {
		grid-template-columns: repeat(6, 1fr);
	}
`

export const CategoryWapper = styled.div`
	display: flex;
	flex-direction: column;
	cursor: cell;
	border-radius: ${Spacings.SPACING_2B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	padding-top: ${Spacings.SPACING_2B};
	&:hover {
		opacity: 0.4;
	}
`
export const ImageWapper = styled.div`
	display: block;
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
`

export const CategoryImage = styled.img`
	display: block;
	width: 100%;
	max-height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	object-fit: contain;
`

export const CategoryTitle = styled.div`
	margin: ${Spacings.SPACING_2B} 0;
	font-size: ${Text.SMALL};
	font-weight: ${Text.BOLD_600};
	text-align: center;
	color: ${({ theme }) => theme.palette.text.darkblack};
`
export const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`

export const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
	& > div {
		display: flex;
		align-items: center;
	}

	${({ TwoInput }) => {
		if (TwoInput) {
			return css`
				justify-content: space-between;
			`
		}
	}}

`
export const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	margin-bottom: ${Spacings.SPACING_2B};
`
export const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
export const SectionItemWrapperWithTwoItemsInRow = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	align-items: baseline;
	& > div {
		flex-basis: calc(50% - ${Spacings.SPACING_2B});
		&:nth-child(2n + 1) {
			margin-right: ${Spacings.SPACING_2B};
		}
		&:nth-child(2n) {
			margin-left: ${Spacings.SPACING_2B};
		}
	}
`

export const PreferredInputWapper = styled.div`
	display: flex;
	align-items: center;
	width: 5rem;
	height: 2rem;
`
export const Errorlabel = styled(Label)`
	color: ${({ theme }) => theme.palette.text.red};
`
