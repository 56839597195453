import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE } from '@base'
import { FieldWrapper, BiFieldWrapper } from '../styles'
import { CountryCodes, strings } from '@constants'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { createOrganisation } from '@data/conn/post/organisation'
import { toggleDrawer } from '@data/state/action/root'
import { getNumberWithCountryCode } from '@utils'
import { getPanByCompanyName } from '@data'

const deriveStateFromData = (data) => {
	const _state = {
		pan: {
			id: 'id',
			value: '',
			valid: true,
			required: false,
		},
		user_name: {
			value: '',
			valid: true,
			required: true,
		},
		mobile_number: {
			value: '',
			valid: true,
			required: true,
		},
	}
	if (!data) return _state

	_state.user_name.value = data.full_name
	_state.mobile_number.value = data.mobile_number?.slice(2)

	return _state
}

const AddNewOrganisation = ({ update, userDetails }) => {
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const dispatch = useDispatch()
	const [details, setDetails] = useState(deriveStateFromData())
	const [countryCode, setCountryCode] = useState(CountryCodes[0])
	const [panByCompanyName, setPanByCompanyName] = useState([])
	const [_orgName, setOrgName] = useState()

	useEffect(() => {
		setDetails((_) => deriveStateFromData(userDetails))
	}, [userDetails])

	const updateState = (field, key) => (value) =>
		setDetails((state) => ({
			...state,
			[field]: {
				...state[field],
				[key]: value,
			},
		}))

	const userNameHander = (orgName) => {
		const userName = details?.user_name?.value?.trim()
		if (userName === _orgName || userName === '') {
			return
		}
		dispatch(showLoader(true))
		getPanByCompanyName(orgName?.trim())
			.then((res) => {
				const _res =
					res?.length > 0
						? res?.map((item) => {
								item['display_name'] = `${item.pan + ', (' + item.company_name + ')'}`
								return item
						  })
						: []
				setPanByCompanyName(_res)
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, err?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				setOrgName(userName)
				dispatch(showLoader(false))
			})
	}

	const disabled = useMemo(() => {
		return Object.values(details).some((d) => !d.valid || (d.required && !d.value))
	}, [details])

	const addOrgHandler = () => {
		let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
		if (typeof details.pan?.value === 'object' && !regpan.test(details.pan?.value?.pan)) {
			dispatch(showToast(true, 'Invalid pan number', { type: TOAST_TYPE.ERROR }))
			return
		}
		dispatch(showLoader(true))
		createOrganisation({
			userName: details.user_name.value,
			countryCode: countryCode.code,
			mobile: getNumberWithCountryCode(countryCode, details.mobile_number.value),
			pan: typeof details.pan?.value === 'object' ? details.pan?.value?.pan : details.pan?.value,
			userId: user?.user_id,
		})
			.then((res) => {
				if (res?.status_code === 400) {
					dispatch(showToast(true, res?.message, { type: TOAST_TYPE.ERROR }))
					return
				}
				dispatch(showToast(true, 'Successfully added organisation', { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while adding organisation', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FieldWrapper>
					<Input
						label={strings('user', 'name')}
						id='user-name'
						type='text'
						value={details?.user_name?.value}
						onChange={updateState('user_name', 'value')}
						setValidity={updateState('user_name', 'valid')}
						placeholder='Enter Org User Name'
						errorMap={{
							valueMissing: `${strings('name_required')}`,
						}}
						onBlur={(e) => {
							userNameHander(e?.target?.value)
						}}
					/>
				</FieldWrapper>
				<FieldWrapper>
					<BiFieldWrapper>
						<Select
							id='org-country-code'
							data={CountryCodes}
							displayKey='dial_code'
							primaryKey='name'
							value={countryCode}
							onChange={setCountryCode}
							disabled={false}
							type='tel'
							noDropIcon
						/>
						<Input
							label={strings('mobile')}
							id='user-mobile'
							type='tel'
							pattern='^\d{7}\d+$'
							maxLength={20}
							errorMap={{
								patternMismatch: `${strings('enter_valid_num')}`,
								valueMissing: `${strings('mob_num_required')}`,
							}}
							value={details?.mobile_number?.value}
							onChange={updateState('mobile_number', 'value')}
							setValidity={updateState('mobile_number', 'valid')}
							placeholder={strings('enter_your_mobile_num')}
						/>
					</BiFieldWrapper>
					{panByCompanyName?.length > 0 ? (
						<Select
							id={details.pan.id}
							label={strings('pan', 'number')}
							placeholder={strings(['select', 'pan', 'number'])}
							data={panByCompanyName}
							displayKey='display_name'
							primaryKey='pan'
							value={panByCompanyName?.find((item) => item?.pan === details.pan.value) ?? (details.pan.value || {})}
							onChange={(val) => {
								updateState('pan', 'value')(val)
							}}
							createNewOption={true}
							maxLength='10'
							uppercase
							pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
						/>
					) : (
						<Input
							type='text'
							label={strings('pan', 'number')}
							value={details?.pan?.value}
							onChange={updateState('pan', 'value')}
							maxLength='10'
							placeholder={strings('enter_valid_pan')}
							pattern='[A-Z]{5}[0-9]{4}[A-Z]{1}'
							errorMap={{
								patternMismatch: strings('enter_valid_pan'),
								valueMissing: strings('pan_required'),
							}}
							setValidity={updateState('pan', 'valid')}
							uppercase
						/>
					)}
				</FieldWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small disabled={disabled} onClick={addOrgHandler}>
					{strings('create', 'organisation')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddNewOrganisation
